import React from 'react';
import FilePicker from './FilePicker';
import './App.css';

function App() {
  return (
    <div className="app">
      <header className="app-header">
        <FilePicker />
      </header>
    </div>
  );
}

export default App;
