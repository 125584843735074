import React, { Component } from 'react'
import * as Magick from 'wasm-imagemagick';
import welcome from './welcome.gif';

export default class FilePicker extends Component {
  state = {
    imageURL: null,
  };

  onChange = e => {
    if (e.target.length < 1 || e.target.files[0] === undefined) {
      console.log('No file selected.');
      return;
    }

    const file = e.target.files[0];
    if (file.type && file.type.indexOf('image') === -1) {
      console.log('File is not an image.', file.type, file);
      return;
    }

    const build = async () => {
      const frames = 60;
      const magickPromises = [];
      for (let i = 0; i < frames; ++i) {
        const process = async () => {
          const sourceImages = await Magick.getInputFilesFromHtmlInputElement(e.target);
          const sourceImage = sourceImages[0];
          const hue = (i / frames) * 360;
          const name = `${i}.gif`;
          const result = await Magick.Call(
            [sourceImage], [
              'convert', sourceImage.name,
              '-fill', `hsl(${hue}, 100, 100)`,
              '-colorize', '60',
              name,
            ],
          );
          return await Magick.asInputFile(result[0]);
        };
        magickPromises.push(process());
      };

      const inputFiles = await Promise.all(magickPromises);
      const inputNames = inputFiles.map(file => file.name);
      const animation = await Magick.Call(
        inputFiles, [
          'convert',
          '-loop', '0',
          '-delay', '3',
          '-layers', 'optimize',
          ...inputNames, 'animation.gif'
        ],
      );
      const blob = new Blob([animation[0].buffer], { type: 'image/gif' });
      const url = URL.createObjectURL(blob);
      this.setState({
        imageURL: url,
      });
    };
    build().then(() => {
      console.log('Done!');
    });
  }

  render() {
    return (
      <>
        <input type='file' id='file' onChange={this.onChange} />
        <label htmlFor='file'>
          {
            this.state.imageURL !== null ?
              <img src={this.state.imageURL} alt='' /> :
              <img src={welcome} className='welcome' alt='welcome!!!' />
          }
        </label>
      </>
    );
  }
}
